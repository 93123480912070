module.exports={
    "projects": [
        {
            "name": "Logotype Designs",
            "category": "graphic-design",
            "url": "logotype-design",
            "subtitle": "Various logotype designs",
            "content": [
                {
                    "type": "subtitle-text",
                    "title": "Logotype Design",
                    "sub": "Logo, trademarks, identities",
                    "text": ""
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/logo-designs/img-1.jpg",
                            "alt": ""
                        },
                        {
                            "value": "/assets/logo-designs/img-2.jpg",
                            "alt": ""
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "Lab Hub",
                    "sub": "Logo design",
                    "text": "Lab Hub mainly serves the newly created individual/studio and creates a series of brand recognition, marketing planning and problem solving based on design."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/logo-designs/img-3.jpg",
                            "alt": ""
                        },
                        {
                            "value": "/assets/logo-designs/img-4.jpg",
                            "alt": ""
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "NanoProtect",
                    "sub": "Wordmark design",
                    "text": "NanoProtect is a circuit protector provides moderate protection against rust from electrical connection switches and mechanical transmission components, providing excellent lubrication for driving components of electrical machinery."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/logo-designs/img-5.jpg",
                            "alt": ""
                        },
                        {
                            "value": "/assets/logo-designs/img-6.jpg",
                            "alt": ""
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "Ramen",
                    "sub": "",
                    "text": "An aesthetic cyberpunk approach to traditional Japanese ramen restaurant with retro american neon light style hybrid."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/logo-designs/img-7.jpg",
                            "alt": ""
                        },
                        {
                            "value": "/assets/logo-designs/img-8.jpg",
                            "alt": ""
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "Iwatori Bahulu",
                    "sub": "Wordmark design",
                    "text": "“Iwatori” means “chicken” in Japanese. Baulu was a traditional Chinese snack found in Brunei and Malaysian which normally, now one of the most popular Taiwanese street food."
                }
            ]
        },
        {
            "name": "Train Ticket",
            "category": "graphic-design",
            "url": "train-ticket",
            "subtitle": "To retain the memory of these precious collections: train tickets.",
            "content": [
                {
                    "type": "fw-img",
                    "value": "/assets/train-ticket/big-img-1.jpg",
                    "alt": "Book Cover"
                },
                {
                    "type": "subtitle-text",
                    "title": "Taiwan Rail ticket stories",
                    "sub": "ISBN: 9789864611423",
                    "text": "Ni li, a 95-year-old photographer, traveled around the stations through decades to collect tens of thousands of rail tickets. In order to retain the memory of these precious collections, his grandson, Ni Jing-Tai, spent more than a year sorting out and looking for interesting and sweet stories, published this book to educate new generations and to sustain the secret and wisdom of these designs."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/train-ticket/img-1.jpg",
                            "alt": "Inner Pages"
                        },
                        {
                            "value": "/assets/train-ticket/img-2.jpg",
                            "alt": "Inner Pages"
                        },
                        {
                            "value": "/assets/train-ticket/img-3.jpg",
                            "alt": "Cover Proposal"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": "The client and I collaborated this project fully remotely including printing paper selecting and printing sample confirming which is quite an interesting experience. With the assistance of online tools, our communications are seamless and effectiveness which leads to a productive, efficient result that both of us are satisfied. "
                },
                {
                    "type": "text",
                    "mod": "mt4 b",
                    "value": "Tools: Adobe Photoshop, Adobe Illustrator, Adobe Acrobat, Adobe Lightroom, Adobe Indesign, Pasteapp, Line, Teamviewer, G Suite"
                }
            ]
        },
        {
            "name": "Signals of Nazi Prisoners",
            "category": "graphic-design",
            "url": "signals-of-nazi-prisoners",
            "subtitle": "A fabric book that demonstrates the signal of Nazi prisoners.",
            "content": [
                {
                    "type": "subtitle-text",
                    "title": "Signals of Nazi Prisoners",
                    "sub": "The Triangle Project",
                    "text": "This project is a study of triangle. I started with a collection of triangles in daily life, futher studied the meaning of triangle in different cultures throughout time. During this project I experiment with materials, played around the feature of paper. All in all, I decided to go with making a fabric book that demonstrates the signal of Nazi prisoners which can be used in educational function or simply a record of the history."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/nazi-prison-signal/img-1.jpg",
                            "alt": "Triangles in daily life"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/img-2.jpg",
                            "alt": "Inner pages"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": "I used the skill of paper folding and paper cutting on a sketch book to make a triangle pop-up book. This sketch book keeps all of my experiments of making integrated triangles."
                },
                {
                    "type": "fw-img",
                    "value": "/assets/nazi-prison-signal/wide-img-1.jpg",
                    "alt": "Paper experiments"
                },
                {
                    "type": "img",
                    "value": "/assets/nazi-prison-signal/img-3.jpg",
                    "alt": "Triangles in daily life"
                },
                {
                    "type": "fw-img",
                    "value": "/assets/nazi-prison-signal/wide-img-2.jpg"
                },
                {
                    "type": "subtitle-text",
                    "title": ["Primary Research:", "br", "Meaningful Triangle"],
                    "text": "If communication be explained literally which can be associated with linguistics and triangle is associated with semiology, what will happen when they are related?"
                },
                {
                    "type": "carousel",
                    "key": "car2",
                    "imgs": [
                        {
                            "value": "/assets/nazi-prison-signal/car1-1.jpg",
                            "alt": "The semantic triangle"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car1-2.jpg",
                            "alt": "The semantic triangle"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car1-3.jpg",
                            "alt": "The semantic triangle"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": [
                        "The semantic triangle is a model of how linguistic symbols are related to the objects they represent. The triangle was published in The Meaning of Meaning (1923) by Ogden and Richards. While often referred to as the “Ogden/Richards triangle” the idea is also expressed in 1810, by Bernard Bolzano, in his Beiträge zu einer begründeteren Darstellung der Mathematik. The Triangle relates to the problem of universals, a philosophical debate which split ancient and medieval philosophers (mainly realists and nominalists)." , "br",
                        "1. The matter evokes the writer’s thought.", "br", 
                        "2. The writer refers the matter to the symbol.", "br",
                        "3. The symbol evokes the reader’s thought.", "br",
                        "4. The reader refers the symbol back to the matter.", "br",
                        "The triangle concerns the relationship between an expression and the subject of that expression. It draws a distinction between referent (a word or non-literal representation e.g. a hieroglyph) and symbol (a literal representation), and sets out and describes the relationships between these and the thought or object that is the subject of them.", "br", 
                        "The semantic triangle inspired me several questions of the meaning of symbols and how people give refernces/ thoughts to objects. I made these two pictures and asked if they are words or symbols?"
                    ]
                },
                {
                    "type": "fw-img",
                    "value": "/assets/nazi-prison-signal/big-img-1.jpg",
                    "alt": "Paper experiments"
                },
                {
                    "type": "subtitle-text",
                    "title": "Fabric Book",
                    "text": "This is the final process of the whole triangle research. I decided to make a book which is about the signal of Nazi prisoner. The idea of making this topic was when I researching the meaning of triangles.\nI found out that Nazis use different colours of triangles marking them on the badges to categrise different kinds of prisoners."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/nazi-prison-signal/img-5.jpg",
                            "alt": "Intro"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/img-6.jpg",
                            "alt": "Process"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/img-7.jpg",
                            "alt": "Process"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/img-8.jpg",
                            "alt": "Process"
                        }
                    ]
                },
                {
                    "type": "carousel",
                    "key": "car3",
                    "imgs": [
                        {
                            "value": "/assets/nazi-prison-signal/car2-1.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car2-2.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car2-3.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car2-4.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car2-5.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car2-6.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car2-7.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/nazi-prison-signal/car2-8.jpg",
                            "alt": "Inner pages"
                        }
                    ]
                },
                {
                    "type": "fw-img",
                    "value": "/assets/nazi-prison-signal/big-img-2.jpg"
                },
                {
                    "type": "text",
                    "mod": "mt4 b",
                    "value": "Tools: Adobe Photoshop, Adobe Illustrator, Adobe Acrobat, needlework, rubber stamp."
                }
            ]
        },
        {
            "name": "TPWL",
            "category": "graphic-design",
            "subtitle": "Taiwan Public Welfare League business card design",
            "url": "TPWL",
            "content": [
                {
                    "type": "fw-img",
                    "value": "/assets/tpwl/concept.jpg",
                    "alt": "Concept"
                },
                {
                    "type": "fw-img",
                    "value": "/assets/tpwl/proposal.jpg",
                    "alt": "Proposal"
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/tpwl/mockup02.jpg",
                            "alt": "Mockup 01"
                        },
                        {
                            "value": "/assets/tpwl/mockup01.jpg",
                            "alt": "Mockup 02"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": "Taiwan Public Welfare League is a foundation to support and to empower the disabled in Taiwan. From immigration, physical disabilities to the poor, the view of which is that societies are more likely to live well together if exclusion is addressed and connections deepened. With compassionate actions being saturated, the foundation builds with the aim of a more socially equal society."
                },
                {
                    "type": "text",
                    "value": "The most interesting and challenging part of business card design within this project, is to save extra places both front and back side especially for Braille printing; also to communicate with printing house about the embossing. The mockup below demonstrates the pre-save area."
                },
                {
                    "type": "text",
                    "value": "Being inspired by the specialty of multiculture, the three colours represent the diversity of Taiwan society, a shaped form of organisms. With layers multiplied as a metaphor for different individuals being engaged and connected, that can become a blossoming flower."
                }
            ]
        },
        {
            "name": "Adventure in Daily life",
            "category": "illustrations",
            "subtitle": "Self-satisfaction and custom-breaking challenge of alter ego.",
            "url": "adventure-in-daily-life",
            "content": [
                {
                    "type": "fw-img",
                    "value": "/assets/aidl/big-img-1.jpg",
                    "alt": ""
                },
                {
                    "type": "fw-img",
                    "value": "/assets/aidl/big-img-2.jpg",
                    "alt": ""
                },
                {
                    "type": "fw-img",
                    "value": "/assets/aidl/big-img-3.jpg",
                    "alt": ""
                },
                {
                    "type": "fw-img",
                    "value": "/assets/aidl/big-img-4.jpg",
                    "alt": ""
                },
                {
                    "type": "carousel",
                    "key": "car1",
                    "imgs": [
                        {
                            "value": "/assets/aidl/fig01.jpg",
                            "alt": "Figure 01"
                        },
                        {
                            "value": "/assets/aidl/fig02.jpg",
                            "alt": "Figure 02"
                        },
                        {
                            "value": "/assets/aidl/fig03.jpg",
                            "alt": "Figure 03"
                        },
                        {
                            "value": "/assets/aidl/fig04.jpg",
                            "alt": "Figure 04"
                        },
                        {
                            "value": "/assets/aidl/fig05.jpg",
                            "alt": "Figure 05"
                        },
                        {
                            "value": "/assets/aidl/fig06.jpg",
                            "alt": "Figure 06"
                        },
                        {
                            "value": "/assets/aidl/fig07.jpg",
                            "alt": "Figure 07"
                        }
                    ]
                },
                {
                    "type": "grid",
                    "imgs": [
                        {
                            "value": "/assets/aidl/fig01.jpg",
                            "alt": "Figure 01"
                        },
                        {
                            "value": "/assets/aidl/fig02.jpg",
                            "alt": "Figure 02"
                        },
                        {
                            "value": "/assets/aidl/fig03.jpg",
                            "alt": "Figure 03"
                        },
                        {
                            "value": "/assets/aidl/fig04.jpg",
                            "alt": "Figure 04"
                        },
                        {
                            "value": "/assets/aidl/fig05.jpg",
                            "alt": "Figure 05"
                        },
                        {
                            "value": "/assets/aidl/fig06.jpg",
                            "alt": "Figure 06"
                        },
                        {
                            "value": "/assets/aidl/fig07.jpg",
                            "alt": "Figure 07"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": "This is a series of illustrations which is about self-satisfaction and custom-breaking challenge of alter ego. All of these behaviours are motivated by desire no matter how much positive/negative affection is to others. The creating process is also an application to the theory, in addition. Every piece of wearing of the character is referred to my wearing on the day when I created the drawing."
                }
            ]
        },
        {
            "name": "Moxa",
            "category": "web-design",
            "url": "moxa",
            "subtitle": "A transformation to conventional industry",
            "content": [
                {
                    "type": "subtitle-text",
                    "title": "Moxa official site revamp",
                    "sub": "",
                    "text": "Moxa provides a full spectrum of quality products for industrial networking, computing, and automation, which design solutions that operate securely and consistently under critical conditions. Striving to understand customers needs, providing service that exceeds expectations that reach in more than 70 countries."
                },
                {
                    "type": "fw-img",
                    "value": "/assets/moxa/big-img-1.jpg",
                    "alt": ""
                },
                {
                    "type": "fw-img",
                    "value": "/assets/moxa/big-img-5.jpg",
                    "alt": ""
                },
                {
                    "type": "subtitle-text",
                    "title": ["Primary Research:", "br", "Discover phase"],
                    "text": "While in the design and industry research phase, we've looked into all the computing and networking related companies that listed in the Fortune 500. After finding out that due to the target audiences and industries character, to arrange massive products and solutions in a well-structured information architecture also implementing a precisely feasible design system to unify visual is a critical. However, it is undoubtedly a challenge to both client and us to settle the design system guideline in a global organisation. On the other hand, by emphasising success stories, case studies to increasing reliable brand image and the variety of product applications is another strategy we intended."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/moxa/img-4.gif",
                            "alt": "A complicated megamenu without a responsive website"
                        },
                        {
                            "value": "/assets/moxa/img-1.jpg",
                            "alt": "Without a union design system, Moxa had 9 headers on one site"
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "Challenges",
                    "text": "A transformation needs a breakthrough. By looking back to the sitemap, we adjust the structure of the website fundamentally. By putting a lot of efforts and studies on product section- which is one of the most important section within site; through the arrangement of the product model table, users now can easily see the differences between specifications. Furthermore, along with a comparison function added, each product category layer offering a filter feature with their own criteria which can also obtain the miscellaneous products."
                },
                {
                    "type": "carousel",
                    "key": "car2",
                    "imgs": [
                        {
                            "value": "/assets/moxa/car1-1.jpg",
                            "alt": "Sitemap"
                        },
                        {
                            "value": "/assets/moxa/car1-2.jpg",
                            "alt": "Flowchart"
                        },
                        {
                            "value": "/assets/moxa/car1-3.jpg",
                            "alt": "Wireframe"
                        }
                    ]
                },
                {
                    "type": "fw-img",
                    "value": "/assets/moxa/big-img-2.jpg",
                    "alt": ""
                },
                {
                    "type": "fw-img",
                    "value": "/assets/moxa/big-img-4.gif",
                    "alt": "A demonstration of product section"
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/moxa/img-5.jpg",
                            "alt": "A quoation userflow design workshop with client"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": "My Moxa section is another challenge. After going through several design versions and wireframe communications, finally reached to a consensus of the UX flow with the behaviour of bookmarking, tagging and following product updates with the client. Another significant feature is to integrate the support application cases with support form submission, not only optimising the submission flow with helpful hints and front-end effects but also smooths the operation, making the experience painless to the user."
                },
                {
                    "type": "carousel",
                    "key": "car3",
                    "imgs": [
                        {
                            "value": "/assets/moxa/car2-1.jpg",
                            "alt": "Sketch"
                        },
                        {
                            "value": "/assets/moxa/car2-2.jpg",
                            "alt": "Sketch"
                        },
                        {
                            "value": "/assets/moxa/car2-3.jpg",
                            "alt": "Flow"
                        },
                        {
                            "value": "/assets/moxa/car2-4.jpg",
                            "alt": "Flow"
                        },
                        {
                            "value": "/assets/moxa/car2-5.jpg",
                            "alt": "Flow"
                        },
                        {
                            "value": "/assets/moxa/car2-6.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/moxa/car2-7.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/moxa/car2-8.jpg",
                            "alt": "Inner pages"
                        },
                        {
                            "value": "/assets/moxa/car2-9.jpg",
                            "alt": "Inner pages"
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "Design Sytem and Style guide",
                    "text": "To make explicitly visual guidelines, we started with the design of essential components. By following the methodology which Brad Frost proposed in his book “Atomic Design”, we overhauled complex pages into pieces. After a clear definition of atomic component usage, a do and don’ts banner design guidelines and colour palettes as our foundation; front-end developers rearrange these components to a fabricator. Centred around the Moxa standard colour, we eliminated and focused on bright, clean linear segments; Not only do these emphasise a vivid image of the corporation, but they also provide sorting information to users. Moxa Design is not only a portal to corporate visual identity, but also a portal to official site design system, official site style guide, and campaign site style guide which drives discoverability and engages users to learn and apply to the Moxa digital contents."
                },
                {
                    "type": "cta-button",
                    "text": "See Prototype",
                    "url": "https://invis.io/QHPA7XIX8JA#/334296663_Landing_-_Menu-Open"
                },
                {
                    "type": "fw-img",
                    "value": "/assets/moxa/big-img-5.gif"
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/moxa/img-2.jpg",
                            "alt": "Design system"
                        },
                        {
                            "value": "/assets/moxa/img-3.jpg",
                            "alt": "Style guide"
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "Breathe new life to convention",
                    "text": "Users now have a more intuitive experience while visiting the Moxa website. After two years of production, working closely with the client and the team, the revamp website leverages the new face while emphasising the ingenuity. The brand underscores that innovation does not occur in a vacuum; it arises from the deep bonds with clients, cemented through global communications, which enable Moxa to create breakthrough solutions that meet evolving client needs."
                },
                {
                    "type": "text",
                    "mod": "mt4 b",
                    "value": "Tools: Adobe Photoshop, Adobe Illustrator, Adobe Acrobat, Invision, Zoom, Skype, G Suite, Jira, Confluence, Sketchapp."
                }
            ]
        },
        {
            "name": "Greenvines",
            "category": "web-design",
            "url": "greenvines",
            "subtitle": "Greenvines E-commerce site revamped",
            "content": [
                {
                    "type": "subtitle-text",
                    "title": "Greenvines: A revolutionary life from genuine skincare",
                    "sub": "",
                    "text": "Greenvines is a unique lifestyle brand that builds both fresh and personal care products to reshape how we can live better, healthier in a more sustainable way. With the belief that 'innovation can be sprouted', the team extended its product lines into green personal care products customised for our bodies’ real and only needs. These are all small things we take for granted in daily life but have a significant impact on our health and environment."
                },
                {
                    "type": "fw-img",
                    "value": "/assets/greenvines/big-img-1.jpg",
                    "alt": ""
                },
                {
                    "type": "subtitle-text",
                    "title": "Challenges",
                    "text": "Harris, who is one of the founder of Greenvines, also a graduate student from MIT Sloan. He carries their culture while leading the brand to achieve more than 30 million dollars a year with 70 per cent through e-commerce. Being partnered with an experienced marketing expert, our challenge not only to translate the brand value into a delightful user experience while expressing the personality of it. The redesign aims to be more 'unified' and 'recognisable' while preventing it from feeling 'disjointed'. While designing solutions with inbound marketing strategy, there is a lot of negotiations and trade-off in the UX perspective. Nevertheless, advanced strategy and SEO was implanted as well as many other features that we both satisfied with the outcome."
                },
                {
                    "type": "fw-img",
                    "value": "/assets/greenvines/big-img-2.jpg",
                    "alt": ""
                },
                {
                    "type": "subtitle-text",
                    "title": ["Primary Research:", "br", "Discover phase"],
                    "text": "We began our engagement with an overhaul of their website. By communicates back and forward, we summed up the essential requirements that would need to be fulfilled. Started from sitemap rearrangement, we tried to recompose all the critical elements together in one canvas. I learned so much from this session, for a lot of times; people usually tend to give 'What to do' feedbacks instead of 'What is the problem'. Through conversations, experiments along with a series of discussions with wireframes, we can discover the pain points hence to suggest the best solution."
                },
                {
                    "type": "carousel",
                    "key": "car2",
                    "imgs": [
                        {
                            "value": "/assets/greenvines/car1-1.jpg",
                            "alt": "Sitemap"
                        },
                        {
                            "value": "/assets/greenvines/car1-2.jpg",
                            "alt": "UI Flow"
                        },
                        {
                            "value": "/assets/greenvines/car1-3.jpg",
                            "alt": "Wireframe"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": "Followed by understanding the needs from Greenvines, the whole site was built to accommodate flexible ordering of different modules. Together with the in-house designers of Greenvines, we created some flexible product stories modules. Product pages can be constructed creatively with some pre-designed sections which give more freedom to the content managers. A tailored made review system is another highlight in our design, by requesting the skin and hair condition from users who write a review can help our clients manage more member information."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/greenvines/img-1.gif",
                            "alt": "Product page modules proposal"
                        },
                        {
                            "value": "/assets/greenvines/img-2.jpg",
                            "alt": "Colour direction proposal"
                        }
                    ]
                },
                {
                    "type": "text",
                    "value": "This is the first time for me to execute the principles and patterns what I've learned from 'Microinteractions' by Dan Saffer, by making UI animated transition to deliver delightful user experience. Through using Lottie by Airbnb as well as bodymovin, it gets effortless and efficient to render animations made by After Effect to a json file for front-end developers to implement. Every detail should be careful to give a better experience to users while visiting the site."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/greenvines/img-3.gif",
                            "alt": "UI animation"
                        },
                        {
                            "value": "/assets/greenvines/img-4.jpg",
                            "alt": "Lottie made by Airbnb and Bodymovin"
                        }
                    ]
                },
                {
                    "type": "subtitle-text",
                    "title": "Closing",
                    "text": "It's a precious experience to collaborate with local founded business. Taiwan has a unique environment in the e-commerce market that there are several gateways to different logistics and payments systems. Not only dealing with global credit card payments, but also Alipay and UnionPay from China. Furthermore, picking up parcels from convenient stores boost the complexity of the designing for user flow. While we've done this much at the research and design stage; unfortunately, after developing the front-end mock-up we came into a chaos of project managing and team members shifting, leads to an outsourcing back-end support. From this, I experience the extra cost of communicating, which thankfully we kept all records in documents that make things easier. While there are ways we could dig this project further, I'm happy with the outcome."
                },
                {
                    "type": "fw-img",
                    "value": "/assets/greenvines/big-img-3.jpg",
                    "alt": ""
                },
                {
                    "type": "text",
                    "mod": "mt4 b",
                    "value": "Tools: Adobe Photoshop, Adobe Illustrator, Adobe After Effect, Adobe Premiere, sketch2ae, Lottie, Adobe Acrobat, Invision, Zoom, G Suite, AxureRP, Jira, Confluence, Sketchapp."
                }
            ]
        },
        {
            "password": ["buffer", "bakkenbaeck","hello", "ideo", "wefunder", "apple"],
            "name": "Fullinn",
            "category": "uiux-design",
            "url": "fullinn",
            "subtitle": "Optimised booking experience and shaping to help hospitality business.",
            "content": [
                {
                    "type": "subtitle-text",
                    "title": "Transformation and evolution of hospitality industry",
                    "sub": "Fullinn Dashboard Design",
                    "text": "Global tourism has brought hospitality industry to another level. There's been a huge movement of traveller's habit about staying reservation and travel habits. Hotels are no longer the perfect choice for voyagers, along with the thriving growth of the sharing economy; b&bs and couch surfing brings the new wave."
                },
                {
                    "type": "fw-img",
                    "value": "/assets/fullinn/big-img-1.jpg",
                    "alt": ""
                },
                {
                    "type": "text",
                    "value": "The team of Fullinn members acquire engineering technology background and as a b&b host themselves found out that it is an unsolved pain point to generic hosts without a well-managed booking system. Trying to build a real problem-solving product, they rolled up the sleeves with an easy to use PMS to amateur hosts. After the rolling of the Fullinn system, they decided to take the service to another level- by offering a website builder which can seamlessly be administrated by hosts. To make the achievement, they came up with a requirement of the design."
                },
                {
                    "type": "fw-img",
                    "value": "/assets/fullinn/big-img-2.gif",
                    "alt": "Dashboard overview"
                },
                {
                    "type": "img",
                    "value": "/assets/fullinn/img-1.jpg",
                    "alt": "Landingpage of builder"
                },
                {
                    "type": "subtitle-text",
                    "title": ["Primary Research:", "br", "Design for purpose"],
                    "text": "We believe that a good user experience influence a product profoundly. After understanding Fullinn's approach and discovering the requirements, we did our research by targeting the audiences- most of them are in middle or senior ages and need longer adapting period to interfaces and services. This was an essential find out which lead us to a solution with a simplified dashboard, text oriented interface."
                },
                {
                    "type": "img",
                    "value": "/assets/fullinn/img-2.jpg",
                    "alt": ""
                },
                {
                    "type": "subtitle-text",
                    "title": "Balance of design and function",
                    "text": "Followed by the research, we enter into the curating of the design system. For this project is a collaboration with Fullinn team (which we only execute design phase), a clear, neaten, comprehensive record and definition of the design system are essential. With the assisting of a well-constructed prototype, a lucid communication is achieved during the process."
                },
                {
                    "type": "album",
                    "imgs": [
                        {
                            "value": "/assets/fullinn/img-3.jpg",
                            "alt": "System"
                        },
                        {
                            "value": "/assets/fullinn/img-4.jpg",
                            "alt": "UI Components"
                        }
                    ]
                },
                {
                    "type": "fw-img",
                    "value": "/assets/fullinn/big-img-3.gif"
                },
                {
                    "type": "fw-img",
                    "value": "/assets/fullinn/big-img-4.jpg"
                },
                {
                    "type": "subtitle-text",
                    "title": "A coherence hospitality ecosystem",
                    "text": "Being part of cultural experience economy, hotels and hostels can bring positive influence to the society by offering delight booking services, room managing and good caring. A better living experience for travelers facilitate the hospitality industry hence sharing the beauty of this nation."
                },
                {
                    "type": "text",
                    "mod": "mt4 b",
                    "value": "Tools: Adobe Photoshop, Adobe Illustrator, Adobe Acrobat, Invision, Zoom, G Suite, Sketchapp."
                }
            ]
        }
    ],
    "categories": [
        {
            "text": "All Projects",
            "link": "/projects/all"
        },
        {
            "text": "Web Design",
            "link": "/projects/web-design"
        },
        {
            "text": "UI/UX Design",
            "link": "/projects/uiux-design"
        },
        {
            "text": "Graphics Design",
            "link": "/projects/graphic-design"
        },
        {
            "text": "Illustrations",
            "link": "/projects/illustrations"
        },
        {
            "text": "Photography",
            "link": "https://celestetsai.tumblr.com/",
            "target": "_blank"
        }
    ]
}
