const html = require('choo/html')
const css = require('sheetify')

const nav = require('./nav')

module.exports = splash

function splash (isBack, categories, browserState) {
    return html`
        <section class="${isBack ? 'dn' : 'vh-100'}">
            ${landing()}
            ${logo()}
            ${nav(categories, browserState)}
        </section>
    `

    function landing () {
        return html`
            <div class="flex flex-column justify-center ${container}">
                <div class="${spContainer}">
                    <a href="/about">
                        <img src="/assets/splash.png">
                    </a>
                </div>
            </div>
        `
    }

    function logo() {
        const style = css`
            :host {
                height: 8%;
                display: none;
            }

            :host img {
                height: 100%;
                max-width: unset;
            }

            @media screen and (min-height: 620px) {
                :host {
                    display: flex;
                }
            }
        `
        return html`
            <a class="justify-center ${style}" href="/projects/all">
                <img src="/assets/header-logo.png" alt="Logo"/>
            </a>
        `
    }
}

const container = css`
    :host {
        height: 85%;
        max-width: 1200px;
        margin: 0 auto;
    }
`

const spContainer = css`
    :host img {
        width: auto;
        height: 85vh;
        max-width: 100%;
        object-fit: cover;
    }

    @media (min-width: 600px) {
        :host {
            padding: 3.5% 8em;
            background: #F8F9F9;
        }
        :host img {
            width: 100%;
            height: 100%;
        }
    }
`
