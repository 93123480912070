const css = require('sheetify')
const choo = require('choo')
const meta = require('choo-meta')
const { categories, projects } = require('./content')

css('tachyons')

const app = choo()

if (process.env.NODE_ENV !== 'production') {
    app.use(require('choo-devtools')())
} else {
    app.use(require('choo-service-worker')())
}

app.use(meta({
    origin: 'https://hsin.co'
}))
app.use(require('./stores/browser'))
app.use(require('./stores/projects'))

const layout = require('./views/layout')

const home = require('./views/home')
const about = require('./views/about')
const filters = require('./views/projects')
const details = require('./views/details')
const notFound = require('./views/404')

for (let category of categories) {
    app.route(category.link, layout(filters))
}
for (let project of projects) {
    app.route(project.url, layout(details))
}
app.route('/about', layout(about, true))
app.route('/', layout(filters))
app.route('/*', layout(notFound))

module.exports = app.mount('body')
