module.exports = function browser (state, emitter) {
    state.browser = {
        width: 1920,
        height: 1080,
        scroll: 0,
        lock: false
    }

    emitter.on('DOMContentLoaded', function () {
        handleResize()
        handleScroll()

        window.addEventListener('resize', function () {
            handleResize()
            if (!state.browser.lock) {
                emitter.emit(state.events.RENDER)
            }
        })

        window.addEventListener('scroll', function () {
            handleScroll()
            if (!state.browser.lock) {
                emitter.emit(state.events.RENDER)
            }
        })

        function handleResize () {
            state.browser.width = window.innerWidth
            state.browser.height = window.innerHeight
        }
        function handleScroll () {
            state.browser.scroll = window.scrollY
        }
    })

    emitter.on('setBrowserLockState', function (newLockstate) {
        state.browser.lock = newLockstate
    })
}
