const html = require('choo/html')
const css = require('sheetify')

module.exports = grids

function grids (projects, emit) {
    const bg = css`
        :host {
            background: #F8F9F9;
            height: 8rem;
            margin: 0.5rem 0;
        }

        @media (min-width: 1000px) {
            :host {
                width: calc(calc(100% / 3) - 1rem);
                margin: 0.5rem;
            }
        }
    `
    const titleStyle = css`
        :host {
            font-size: 1.125rem;
        }
    `
    const subStyle = css`
        :host {
            font-size: 1rem !important;
            letter-spacing: 0.5px !important;
        }
    `
    return html`
        <div class="w-100 pa2">
            ${projects.map(single)}
        </div>
    `
    function single (project) {
        return html`
            <a href="/${project.url}" onclick="${setProject(project)}">
                <article class="fl w-100 pa3 ${bg}">
                    <h3 class="mb0 mt0 black-90 ${titleStyle}">${project.name}</h3>
                    <p class="fw4 mt2 black-60 ${subStyle}">${project.subtitle}</p>
                </article>
            </a>
        `

        function setProject(project) {
            return function () {
                emit('GotoProject', project)
            }
        }
    }
}
