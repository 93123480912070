const html = require('choo/html')
const raw = require('nanohtml/raw')
const css = require('sheetify')

const splash = require('./splash')

module.exports = function (content, shouldUseBackButton = false) {
    return function (state, emit) {
        // global og meta tags
        if (typeof state.meta['og:image'] === 'undefined') {
            emit('meta', { 'og:image': '/assets/og_image.jpg' })
            emit('meta', { 'og:type': 'website' })
            emit('meta', { 'og:description': 'Celeste Tsai is a multidisciplinary designer based in Taiwan. Lover of all things digital. Maker of brands, products and experiences. Invision, sketch, Adobe suite heavy user, coffee lunatic.' })
        }

        // const shouldFix = state.browser.scroll > state.browser.height - (state.browser.scroll * 0.2);
        const isBackButton = css`
            :host {
                margin-top: 0;
            }
        `
        const container = css`
            :host {
                height: 95vh;
            }
        `
        const body = css`
            :host {
                margin: 0;
                font-family: 'Open Sans', sans-serif;
            }
            :host h1, h2, h3 {
                font-family: 'Archivo Black', sans-serif;
            }
            :host h2 {
                font-weight: 400;
                letter-spacing: 1px;
            }
            :host h3 {
                font-weight: 400;
                letter-spacing: 0.2px;
            }
            @media (min-width: 600px) {
                :host {
                    margin: 0 2.5rem;
                }
            }
        `
        const transition = css`
            :host {
                transition: margin 0.2s;
            }
        `
        const paragraphStyles = css`
            :host p {
               line-height: 1.25;
               font-size: 1rem;
               letter-spacing: 1px;
            }
            @media (min-width: 30em) {
                :host p {
                   line-height: 1.5;
                   font-size: 1.125rem;
                   letter-spacing: 1px;
                }
            }
        `
        const footer = css`
            :host {
                font-size: 0.725rem;
                font-weight: 400;
                letter-spacing: 0.2px;
                font-family: 'Open Sans', sans-serif;
                color: #DDD;
                text-align: center;
            }
        `
        return html`
            <body class="${body}">
                ${splash(isBack(), state.categories, state.browser)}
                <div class="${isBackStyle(isBack())} ${paragraphStyles} flex flex-column justify-between">
                    ${content(state, emit)}
                    <footer class="pb4 ${footer} flex items-center justify-center flex-column flex-row-ns">
                        <span class="">© TWENTY NINETEEN CELESTE HSIN-HSIN TSAI${raw`&nbsp;`}</span><span>ALL RIGHTS RESERVED</span>
                    </footer>
                </div>
                <style>
                    @import url('https://fonts.googleapis.com/css?family=Archivo+Black|Open+Sans');
                </style> 
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-126388800-1"></script>
                <script>
                      window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());

                  gtag('config', 'UA-126388800-1');
                </script>
            </body>
        `
        function isBackStyle (isBack) {
            // const mtF = css`:host { min-height: 100vh; max-width: 1200px; margin: 0 auto; }`
            const mt5 = css`:host { min-height: 92vh; margin-top: 8vh; max-width: 1200px; margin: 0 auto; }`
            const mt0 = css`:host { margin-top: 0vh; }`
            
            // if (isBack) {
            //     return mt0;
            // } else if (shouldFix) {
            //     return mt5;
            // } else {
            //     return mtF;
            // }

            return isBack ? mt0 : mt5
        }

        function backButton () {
            const back = css`
                :host {
                    height: 5vh;
                    width: 100%;
                }
            `
            return html`
                <a class="mt3 f5 link" onclick="${() => history.go(-1)}" href="/">Back</a>
            `
        }
        function isBack () {
            return shouldUseBackButton
        }
    }
}

