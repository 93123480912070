const html = require('choo/html')
const css = require('sheetify')

module.exports = about

function about (state, emit) {
    const title = 'About - hsin.co'
    if (state.title !== title) {
        emit(state.events.DOMTITLECHANGE, title)
        emit('meta', { title })
    }

    const list = css`
        :host dt {
            width: 3rem;
            display: inline-block;
            line-height: 1.78;
        }

        :host dd {
            display: inline-block;
            line-height: 1.78;
        }
    `
    const titleStyle = css`
        :host {
            font-size: 2rem;
        }
    `
    const container = css`
        :host {
            max-width: 1200px;
        }
    `
    const lh78 = css`
        :host {
            line-height: 1.78;
        }
    `
    const aWidth = css`
        :host {
            width: 100% !important;
        }
    `
    const leftIcon = html`
        <svg viewBox="0 0 100 100" class="mr2" style="height: 18px; pointer-events: none;">
            <style type="text/css"> .st0{fill:#4A4A4A;} </style>
            <title>chevronLeft</title>
            <g><polygon id="Path-2" class="st0" points="81.4,8.7 72.4,-0.3 22.2,49.9 72.4,100 81.4,91 40.3,49.9" /></g>
        </svg>
    `
    const backStyle = css`
        :host {
            font-size: 1.2rem;
        }
    `
    const bgImg = css`
        :host {
            background-image: none;
        }
        @media (min-width: 55rem) {
            :host {
                background-image: url(/assets/about.jpg);
                background-position: right top 5rem;
                background-size: 700px;
                background-repeat: no-repeat;
                min-height: 900px;
            }
        }
    `

    return html`
        <div class="mh3 mb5 mb6-ns center-ns ${container} ${bgImg}">
            <a class="mt4 link inline-flex black-80 items-center ${backStyle}" href="/projects/all">${leftIcon} Back</a>
            <h1 class="mt5 ${titleStyle}">Good Day!</h1>
            <p class="w-100 w-50-ns mv4 f3">After rolling around in several areas of design, finally, settle with technology. Skilled in photography, illustration, filmmaking, graphic design and UI/UX. After a module in the first semester of my MA written down design manifesto, I believe that Design is an intention to solve problems. Working and playing are often at odds, but my perfect day involves work that feels like play. I respect the diversity of individualisation but emphasise the unification in specific moments. A pixel-perfector and a dash of humour.</p>
            <dl class="w-100 w-50-ns ${list}">
                <div class="flex flex-row-ns flex-column">
                    <dt class="b">Current</dt>
                    <dd class="mt0-ns ml0 ml5-ns">Associate Art Director @ <a class="black-60 link underline-hover" href="https://tenten.co" target="_blank">Tenten</a></dd>
                </div>
                <div class="mv2 flex flex-row-ns flex-column">
                    <dt class="b">Contact</dt>
                    <dd class="mt0-ns ml0 ml5-ns"><a class="black-60 link underline-hover" href="mailto:hsin.co">hi@hsin.co</a></dd>
                </div>
                <div class="flex flex-row-ns flex-column">
                    <dt class="b">Follow</dt>
                    <dd class="mt0-ns ml0 ml5-ns">
                        <a class="black-60 link underline-hover" href="https://dribbble.com/hsinloved" target="_blank">Dribbble</a>, 
                        <a class="black-60 link underline-hover" href="https://www.behance.net/hsinloved" target="_blank">Behance</a>, 
                        <a class="black-60 link underline-hover" href="https://www.linkedin.com/in/celeste-tsai-5a8223a9/" target="_blank">LinkedIn</a>, 
                        <a class="black-60 link underline-hover" href="https://www.instagram.com/hsinloved/" target="_blank">Instagram</a>, 
                        <a class="black-60 link underline-hover" href="https://twitter.com/HsinLoved" target="_blank">Twitter</a>
                    </dd>
                </div>
            </dl>
            <dl class="w-100 w-50-ns ${list}">
                <div class="flex flex-row-ns flex-column">
                    <dt class="b">Education</dt>
                    <dd class="mt0-ns ml0 ml5-ns ${lh78}">
                        MA in Communication Design: Graphic Design at Kingston University London 2013-2014
                        <br />
                        BA in Digital Media Desgin at Ming Chuan University 2010-2013
                    </dd>
                </div>
            </dl>
            <dl class="w-100 w-50-ns ${list}">
                <div>
                    <dt class="b ${aWidth}"><a class="pdf black-60 link underline-hover" href="/assets/resume.pdf" target="_blank" rel="noopener noreferrer">::CV Here</a></dt>
                    <a href="/assets/celeste-portfolio-op.pdf" target="_blank" rel="noopener noreferrer" class="pdf black-60 link underline-hover">::PDF Here</a>
                    <dd></dd>
                </div>
            </dl>
        </div>
    `
}
