const html = require('choo/html')
const htmlRaw = require('nanohtml/raw')
const css = require('sheetify')
const nav = require('../nav')
const Component = require('choo/component')

module.exports = details

function details (state, emit) {
    const selectedProject = state.projects.find(p => `/${p.url}` === state.href)
    const title = `${selectedProject.name} - hsin.co`
    if (state.title !== title) {
        emit(state.events.DOMTITLECHANGE, title)
        emit('meta', { title })
    }

    const imgShadow = css`
        :host {
            box-shadow: 0px 2px 10px 0px rgba(155,155,155,0.2);
        }
    `

    return html`
        <article class="mh3 mb5 mb6-ns mt5" data-article="">
            <h1 class="dn">${selectedProject.name}</h1>
            ${checkPassword(selectedProject)}
        </article>
    `

    function checkPassword (project) {
        const content = html`
            <div class="w-100">${project.content.map(chapter)}</div>
        `
        const pwModal = css`
            :host {
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                z-index: 2;
                position: fixed;
                background: #484848;
            }
        `
        const text = css`
            :host {
                font-size: 1.25rem;
                font-weight: 400;
                letter-spacing: 0.2px;
                font-family: 'Archivo Black', sans-serif;
                text-align: center;
            }
        `

        if (selectedProject.unlocked === true) {
            return content
        }

        if (project.hasOwnProperty('password')) {
            return html`
                <div class="flex justify-center items-center flex-column ${pwModal}">
                    <div class="flex justify-center items-center flex-column " style="padding-right: 16px;">
                        <p class="white ${text}">Due to confidentiality issues<br>this article is password locked</p>
                        <input class="input-reset ba b--black-20 pa2 mb2 db w-200" placeholder="Password" data-password="" onkeydown="${enterDown}" />
                        <div class="mt3 flex justify-between w-200">
                            <a class="f6 link ba ph3 pv2 mb2 dib white mh3 b--white bg-transparent" href="/projects/all">Back</a>
                            <button class="f6 link ba ph3 pv2 mb2 dib black mh3 b--white bg-white" onclick="${onUnlock}">Unlock</button>
                        </div>
                    </div>
                </div>
            `
        }

        return content

        function enterDown (ev) {
            if (ev.keyCode === 13) {
                onUnlock()
            }
        }

        function onUnlock (ev) {
            const pw = document.querySelector('[data-password]').value || ''
            const passwords = Array.isArray(project.password) ? project.password : [project.password]
            if (passwords.includes(pw.toLowerCase())) {
                const article = document.querySelector('[data-article]')
                const target = article.children[1]
                article.removeChild(target)
                article.appendChild(content)
                selectedProject.unlocked = true
            }
        }

    }

    function chapter (item) {
        switch (item.type) {
            case 'subtitle-text':
                return subtitleText(item);
                break;
            case 'img':
                return image(item);
                break;
            case 'img2':
                return image2(item);
                break;
            case 'fw-img':
                return fwImage(item);
                break;
            case 'carousel':
                return carousel(item);
                break;
            case 'grid':
                return grid(item);
                break;
            case 'album':
                return album(item);
                break;
            case 'cta-button':
                return ctaButton(item);
                break;
            default:
                return paragraph(item);
                break;
        }
    }

    function paragraph (item) {
        return html`
            <p class="mv4 ${item.mod ? item.mod : ''}">${newLine(item.value)}</p>
        `
    }

    function image (item) {
        return html`
            <figure class="mv4 mh0 w-50-ns w-100 ${item.mod ? item.mod : ''}">
                <img class="${imgShadow} w-100" src="${item.value}" alt="${item.alt}" />
                ${(item.alt) ? html`<figcaption class="i mid-gray mt2">${item.alt}</figcaption>` : ''}
            </figure>
        `
    }

    function image2 (item) {
        const w49 = css`
            @media screen and (min-width: 30em) {
                :host {
                    width: calc(calc(100% / 2) - 1rem);
                }
            }
        `
        return html`
            <div class="mv4">
                <div>
                    <div class="fl-ns mh2-ns ${w49}">
                        <figure class="ma0 w-100">
                            <img class="${imgShadow}" src="${item.value[0].value}" alt="${item.value[0].alt}" />
                        </figure>
                    </div>
                    <div class="fl-ns mh2-ns ${w49}">
                        <figure class="ma0 w-100">
                            <img class="${imgShadow}" src="${item.value[1].value}" alt="${item.value[1].alt}" />
                        </figure>
                    </div>
                </div>
            </div>
        `
    }

    function album (item) {
        const w49 = css`
            @media screen and (min-width: 30em) {
                :host {
                    width: calc(calc(100% / 2) - 0.8rem);
                }
            }
        `
        const album = css`
            :host figure:first-child {
                margin-top: 0;
            }
            :host figure:last-child {
                margin-top: 0;
                margin-bottom: 2rem;
            }
            :host figure:not(:first-child):not(:last-child) {
                margin-top: 0;
            }
            @media screen and (min-width: 30em) {
                :host figure {
                    margin-top: 2rem;
                    margin-bottom: 2rem;
                }
            }
        `
        item.imgs = item.imgs.map(i => {
            i.mod = `${w49}`
            return i
        })
        return html`
            <div class="mv4 flex flex-row flex-wrap justify-between ${album} ${(item.mod) ? item.mod : ''}">
                ${item.imgs.map(image)}
            </div>
        `
    }

    function fwImage (item) {
        return html`
            <figure class="pv4 mh0 w-100 ${item.mod ? item.mod : ''}">
                <img class="${imgShadow} w-100" src="${item.value}" alt="${item.alt}" />
                ${ (item.alt) ? html`<figcaption class="i mid-gray mt2">${item.alt}</figcaption>` : '' }
            </figure>
        `
    }

    function subtitleText (obj) {
        return html`
            <div class="flex flex-row-ns flex-column mv4 ${(obj.mod) ? obj.mod : ''}">
                <div class="w-50-ns">
                    <h2 class="ma0 mb2 mb0-ns mv2-ns">${newLine(obj.title)}</h2>
                    ${obj.sub ? sub(obj.sub) : ''}
                </div>
                <div class="w-50-ns mt2 ma0-ns ml4-ns">
                    <p class="ma0 mv2-ns">${obj.text}</p>
                </div>
            </div>
        `;
        function sub (text) {
            const clr = css`
                :host {
                    color: gray;
                }
            `
            return html`
                <p class="mt0 ${clr}">${text}</p>
            `
        }
    }

    function carousel (item) {
        const key = item.key
        if (state._carousel[key] === undefined) {
            state._carousel[key] = 0;
        }
        const mb = css`
            :host {
                margin-bottom: 2.85rem;
                margin-top: 1.3rem;
            }
            @media (min-width: 30rem) {
                :host {
                    margin-top: 0;
                }
            }
        `
        const leftIcon = html`
            <svg viewBox="0 0 100 100" style="height: 18px; pointer-events: none;">
                <style type="text/css"> .st0{fill:#4A4A4A;} </style>
                <title>chevronLeft</title>
                <g><polygon id="Path-2" class="st0" points="81.4,8.7 72.4,-0.3 22.2,49.9 72.4,100 81.4,91 40.3,49.9" /></g>
            </svg>
        `
        const rightIcon = html`
            <svg viewBox="0 0 100 100" style="height: 18px; pointer-events: none;">
                <style type="text/css"> .st0{fill:#4A4A4A;} </style>
                <title>chevronRight</title>
                <g><polygon id="Path-2" class="st0" points="22.2,8.7 31.3,-0.3 81.4,49.9 31.3,100 22.2,91 63.3,49.9" /></g>
            </svg>
        `
        const npSt = css`
            :host {
                padding: 1rem;
                cursor: pointer;
                border: 1px solid #979797;
            }
            @media (min-width: 30rem) {
                :host {
                    padding: 1.5rem;
                }
            }
        `
        const mw50 = css`
            @media (min-width: 30rem) {
                :host {
                    max-width: 50%;
                }
            }
        `
        const el = html`
            <div class="mv4 flex-ns">
                <div class="m-100 ${mw50}">${item.imgs.map(single)}</div>
                <div class="ml0 ml2-ns ${mb} self-end flex-ns items-center justify-center mt2 mt0-ns">
                    <button onclick="${page('prev')}" class="${npSt} f5 bg-white no-underline black inline-flex items-center border-box mr2">${leftIcon}</button>
                    <button onclick="${page('next')}" class="${npSt} f5 bg-white no-underline black inline-flex items-center border-box">${rightIcon}</button>
                </div>
            </div>
        `;

        return el;

        function single (item, idx) {
            return html`
                <figure class="mh0 ${idx !== state._carousel[key] ? ' dn' : ''}" data-idx="${idx}">
                    <img class="${imgShadow}" src=${item.value} alt=${item.alt} />
                    <figcaption class="i mid-gray mt2">${item.alt}</figcaption>
                </figure>
            `
        }

        function page (dir) {
            return function (ev) {
                const imgDivs = ev.target.parentNode.parentNode.children[0].children
                let next = null
                for (let i = state._carousel[key]; i < imgDivs.length; i++) {
                    if (!imgDivs[i].classList.contains('dn')) {
                        imgDivs[i].classList.add('dn')
                        if (dir === 'next') {
                            next = Number(imgDivs[i].getAttribute('data-idx')) + 1
                        } else if (dir === 'prev') {
                            next = Number(imgDivs[i].getAttribute('data-idx')) - 1
                        }

                        state._carousel[key] = next;
                        if (next >= imgDivs.length) {
                            next = 0
                            state._carousel[key] = 0;
                        }
                        if (next < 0) {
                            next = imgDivs.length - 1
                            state._carousel[key] = imgDivs.length - 1;
                        }
                        imgDivs[next].classList.remove('dn')
                        break;
                    }
                }
            }
        }
    }

    function grid (item) {
        class ImgModal extends Component {
            constructor () {
                super()
                this.img = null
            }

            createElement (img) {
                this.img = img

                if (this.img === null) {
                    return html`<div></div>`
                }

                const fullscreenModal = css`
                    :host {
                        width: 100vw;
                        height: 100vh;
                        top: 0;
                        left: 0;
                        z-index: 2;
                        position: fixed;
                        background: #48484899;
                    }
                `
                const inner = css`
                    :host {
                        max-width: 900px;
                    }
                `
                const maxWidth = css`
                    :host {
                        max-width: 70vw;
                    }
                `
                return html`
                    <div class="${fullscreenModal} flex justify-center items-center" data-modal="true" onclick="${this.closeModal.bind(this)}">
                        <div class="${inner} bg-black w-50-ns w-80-m w-100 flex justify-center items-center flex-column">
                            <img src="${this.img.value}">
                            <div class="w-100 bg-white pa2 pa3-m pa4-ns">
                                <p class="ma0">${this.img.alt}</p>
                            </div>
                        </div>
                    </div>
                `
            }

            update (newImg) {
                if (this.img === null) {
                    emit('setBrowserLockState', true)
                    document.querySelector('body').style.overflow = 'hidden'
                    lockTouchMove(true)
                } else {
                    emit('setBrowserLockState', false)
                    document.querySelector('body').style.overflow = ''
                    lockTouchMove(false)
                }
                return newImg !== this.img
            }

            closeModal (ev) {
                if (ev.target.getAttribute('data-modal') === 'true') {
                    this.render(null)
                }
            }
        }

        const modal = new ImgModal()

        return html`
            <div>
                <div class="flex flex-row flex-wrap">
                    ${item.imgs.map(singleGrid)}
                </div>
                ${modal.render(null)}
            </div>
        `
        function singleGrid (img) {
            return html`
                <div class="w-third-ns w-50-m pa1 pa2-ns" onclick="${openModal(img)}">
                    <img src="${img.value}" />
                </div>
            `
        }
        function openModal (img) {
            return function () {
                modal.render(img)
            }
        }
    }

    function ctaButton (item) {
        return html`
            <div class="flex justify-center mv5">
                <a href="${item.url}" class="f4 link ba ph4 pv3 dib black b" target="__blank">${item.text}</a>
            </div>
        `
    }

    function newLine (text) {
        let result = text
        if (Array.isArray(text)) {
            result = text.map(t => (t === 'br') ? html`<br/>` : t)
        }
        return result
    }
}

function touchMoveHandler (ev) {
    ev.preventDefault()
}
function lockTouchMove (isLocked) {
    if (isLocked ===  true) {
        document.querySelector('body').addEventListener('touchmove', touchMoveHandler, false)
    } else if (isLocked === false) {
        document.querySelector('body').removeEventListener('touchmove', touchMoveHandler, false)
    }
}
