const html = require('choo/html')
const css = require('sheetify')
const { scroll } = require('../lib/utils')

module.exports = nav

function nav (categories, browser) {
    const shouldFix = browser.scroll > browser.height - (browser.scroll * 0.0899);
    return html`
        <nav class="flex flex-column items-center justify-center overflow-auto w-100 pv3 ${container} ${shouldFix ? fixed : ''}">
            <div class="nowrap mw9 flex-ns justify-center w-100 ${shouldFix ? 'center' : ''}">
                ${navLinks()}
            </div>
        </nav>
    `

    function navLinks () {
        return categories.map(category => {
            if (category.target) {
                return html`<a class="dib h-100 link dim dark-gray f5 dib mh3-ns mh2" href="${category.link}" target="${category.target}">${category.text}</a>`
            }
            return html`<a class="dib h-100 link dim dark-gray f5 dib mh3-ns mh2" href="${category.link}" onclick="${scrollToContent()}">${category.text}</a>`
        })
    }
    function scrollToContent(top = false) {
        return function () {
            scroll(top ? -browser.scroll : browser.height)
        }
    }
}


const container = css`
    :host {
        background: white;
        height: 8%;
        max-width: 1200px;
        margin: 0 auto;
        min-height: 3rem;
    }
`

const fixed = css`
    :host {
        max-width: unset;
        height: 8%;
        position: fixed;
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
        z-index: 1;
        box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    }
`
