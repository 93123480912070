const _scroll = require('scroll')

module.exports = { scroll }

function scroll (distance) {
    return new Promise(function (resolve, reject) {
        _scroll.top(document.documentElement, distance, function (err) {
            if (err && err.message !== 'Element already at target scroll position') {
                return reject(err)
            }
            return resolve()
        })
    })
}

