const { scroll } = require('../lib/utils')
const { projects, categories } = require('../content')

module.exports = function (state, emitter) {
    state.lastCategory = 'all'
    state._carousel = {}

    function handleScroll () {
        const category = state.route.split('/')[1] || ''
        const detail = state.projects.find(p => p.url === state.route)
        const about = state.route === 'about'
        if (about) {
            scroll(0)
            return
        }
        if ((category || detail)) {
            const scrollLen = detail ? state.browser.height - (state.browser.height * 0.08) : state.browser.height
            scroll(scrollLen)
        }
    }

    emitter.on('navigate', handleScroll)
    emitter.on('DOMContentLoaded', handleScroll)

    emitter.on('GotoProject', function (project) {
        state.selectedProject = project
    })

    state.categories = categories

    state.projects = projects

    state.projects.getCategory = function (category) {
        if (category === 'all') return this
        return this.filter(project => project.category === category)
    }
}
