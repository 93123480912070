const html = require('choo/html')

const grids = require('./grids')

const TITLE = 'hsin.co'

module.exports = view

function view (state, emit) {
    if (state.title !== TITLE) {
        emit(state.events.DOMTITLECHANGE, TITLE)
        emit('meta', { title: TITLE })
    }

    const category = state.route.split('/')[1]

    state.lastCategory = category || state.lastCategory
    if (!(state.browser.scroll === 0 && category)) {
        if (isScrolledHalf()) {
            if (state.route === '/') {
                emit('pushState', `/projects/${state.lastCategory}`)
            }
        } else {
            if (state.route !== '/') {
                emit('pushState', '/')
            }
        }
    }

    const projects = state.projects.getCategory(state.lastCategory)
    const lastCategoryName = state.categories.find(c => c.link.indexOf(category) >= 0)
    const nextTitle = `${lastCategoryName.text} - hsin.co`
    if (state.title !== nextTitle) {
        emit(state.events.DOMTITLECHANGE, nextTitle)
        emit('meta', { title: nextTitle })
    }

    return html`
        <section>
            ${grids(projects, emit)}
        </section>
    `

    function isScrolledHalf() {
        return state.browser.scroll > (0.8 * state.browser.height)
    }
}
